export const dataDe = {
  section1: {
    logoImg: "imgs/Girl&Logo3D_2.png",
    version: 12,
    blueTitle: "ﬂexmobility platform",
    title: "ist nun verfügbar!",
    text: "Steigern Sie die Leistungsfähigkeit  Ihrer Business Apps mit der ﬂexmobility platform 12 und starten Sie direkt mit der Konﬁguration Ihrer Business Anforderungen!",
  },
  section2: {
    title: " Vieles startet mit einem Template",
    text: "Unsere Templates für Ihre Business Anforderungen",
  },
  cards: [
    {
      img: "imgs/blue.png",
      sub_img: "imgs/Lager.png",
      title: "Lager Template",
      text: "Mit unserem Template für Lager und Logistik haben Sie bereits eine Vielzahl an vorgefertigten Prozessabläufen. Wareneingang, Umlagerung, Kommissionierung und vieles mehr.",
    },
    {
      img: "imgs/blue.png",
      sub_img: "imgs/CRM.png",
      title: "CRM Template",
      text: "Das flexmobility CRM Template ordnet Ihre Kundenbeziehungen schnell und effizient. Nutzen Sie Ihr CRM System und konfigurieren Sie eine Oberfläche die Ihre Mitarbeiter schätzen werden.",
    },
    {
      img: "imgs/blue.png",
      sub_img: "imgs/Sales.png",
      title: "Webshop Template",
      text: "B2B ode B2C? Mit dem flexmobility Webshop Template haben Sie alle Möglichkeiten aus einer Hand! Keine proprietären Shop-Lösungen mehr und alles konfigurierbar!",
    },
    {
      img: "imgs/blue.png",
      sub_img: "imgs/Service.png",
      title: "Service Template",
      text: "Statt Zettel und Stift, nutzen Sie das Service Template der flexmobility platform. Im Handumdrehen werden Ihre Servicetechniker mobil und steigern die Effizienz.",
    },
  ],
  section3: {
    logoImg: "imgs/Girl_Logo_1.png",
    blueTitle: "ﬂexmobility platform",
    title: "In Rekordzeit von der Idee zur App!",
    text: "Die flexmobiltiy platform wird im Release 12 alle Ihre Anforderungen an die Erstellung von echten Business-Apps und Webportalen abdecken.",
  },
  cards1: [
    {
      img: "",
      title: "Neue Technologie und Code",
      text: "Durch konsequente Neuentwicklung unter Nutzung der modernsten technologischen Möglichkeiten bietet das neue Release 12 der flexmobility platform einen umfassenden Leistungsschub sowie ein solides Fundament für zukünftige Weiterentwicklung. Es stellt sicher, dass Unternehmen effizienter arbeiten können und den ständig wachsenden Anforderungen an die Technologie gerecht werden.",
    },
    {
      img: "",
      title: "Stark in Sicherheit und Skalierbarkeit",
      text: "Ein weiteres Highlight des neuen Releases 12 ist die verbesserte Skalierbarkeit und die Verstärkung von Sicherheitsfunktionen, um die Plattform vor Bedrohungen und Angriffen zu schützen. Durch modernste Verschlüsselungstechnologien und Zugriffskontrollen wird sichergestellt, dass Daten und Informationen stets sicher und vertraulich genutzt werden.",
    },
    {
      img: "",
      title: "Performance Booster",
      text: "Das neue Release 12 der flexmobility platform enthält eine Vielzahl von Verbesserungen, die sie noch effektiver und leistungsfähiger machen. Die Plattform ist nun sowohl im Client- wie auch im Bereich der Servertechnologie noch einmal deutlich Code-effizienter. Das macht die Lösung in vielen Bereichen noch performanter was es Unternehmen ermöglicht, Lösungen auf Basis der flexmobility platform 12 mit bestmöglicher App- und Web-Performance zu betreiben.",
    },
    {
      img: "",
      title: "Supported Platform Ansatz",
      text: "Bei flexmobility platform ist der Support ein elementarer Faktor für die einfache und beschwerdefreie Nutzung der darauf basierenden Lösungen. Dabei wurden ebenfalls wichtige Neuerungen in flexmobility platform 12 implementiert, die für eine hohe Stabilität und Aktualität der Plattform sorgen.",
    },
  ],
  navbar: [
    { title: "Blog", link: "https://flexmobility.de/blog/" },
    { title: "Support", link: "https://flexmobility.de/support/" },
    { title: "Kontakt", link: "https://flexmobility.de/kontakt/" },
  ],
  sozialIcons: [
    {
      title: "Facebook",
      img: "imgs/Facebook_1.png",
      link: "https://www.facebook.com/3mobilitysolutions/",
    },
    {
      title: "LinkedIn",
      img: "imgs/Linkedin_1.png",
      link: "https://www.linkedin.com/company/3mobility-solutions-gmbh/",
    },
  ],
  downloadStore: [
    {
      title: "Ios",
      img: "imgs/app.png",
      link: "https://apps.apple.com/de/app/flexmobility-platform-12/id6462437937",
    },
    {
      title: "Android",
      img: "imgs/google.png",
      link: "https://play.google.com/store/apps/details?id=com.x3ms.flexmp12&gl=DE",
    },
    {
      title: "Windows",
      img: "imgs/microsoft.png",
      link: "https://apps.microsoft.com/detail/flexmobility-platform-12/9P4RHGH19WF5?hl=de-de&gl=DE",
    },
  ],
  buttons: {
   btn1 : 'Jetzt starten',
   btn2: 'Jetzt starten',
  },

  footer: {
    contact : 'Kontakt',
    PrivacyPolicy: 'Datenschutz',
    SiteNotice : 'Impressum',
    GTC: 'AGB',
   },
};



// English Version
export const dataEn = {
  section1: {
    logoImg: "imgs/Girl&Logo3D_2.png",
    version: 12,
    blueTitle: "ﬂexmobility platform",
    title: "is now available!",
    text: "Boost your business apps with ﬂexmobility platform 12 and start conﬁgurating your business needs right away! ",
  },
  section2: {
    title: "Everything starts with a template ",
    text: "Our templates for your business requirements",
  },
  cards: [
    {
      img: "imgs/blue.png",
      sub_img: "imgs/Lager.png",
      title: "Warehouse Template",
      text: "With our template for warehouse and logistics you already have a variety of ready-made process flows. Goods receipt, stock transfer, picking and much more. ",
    },
    {
      img: "imgs/blue.png",
      sub_img: "imgs/CRM.png",
      title: "CRM Template",
      text: "The flexmobility CRM template organizes your customer relationships quickly and efficiently. Use your CRM system and configure an interface that your employees will appreciate. ",
    },
    {
      img: "imgs/blue.png",
      sub_img: "imgs/Sales.png",
      title: "Webshop Template ",
      text: "B2B or B2C? With the flexmobility webshop template you have all possibilities from one source! No more proprietary store solutions and everything is configurable!",
    },
    {
      img: "imgs/blue.png",
      sub_img: "imgs/Service.png",
      title: "Service Template",
      text: "Instead of pen and paper, use the flexmobility platform service template. In the blink of an eye, your service technicians become mobile and increase efficiency.",
    },
  ],
  section3: {
    logoImg: "imgs/Girl_Logo_1.png",
    blueTitle: "ﬂexmobility platform",
    title: "From idea to app in record time!",
    text: "Through consistent new development using the latest technological possibilities, the new release 12 of the flexmobility platform offers a comprehensive performance boost as well as a solid foundation for future further development. It ensures that companies can work more efficiently and meet the constantly growing demands on technology.",
  },
  cards1: [
    {
      img: "",
      title: "New technology and code",
      text: "Through consistent new development using the latest technological possibilities, the new release 12 of the flexmobility platform offers a comprehensive performance boost as well as a solid foundation for future further development. It ensures that companies can work more efficiently and meet the constantly growing demands on technology.",
    },
    {
      img: "",
      title: "Strong in security and scalability",
      text: "Another highlight of the new Release 12 is the improved scalability and strengthening of security features to protect the platform from threats and attacks. State-of-the-art encryption technologies and access controls ensure that data and information are always used securely and confidentially.",
    },
    {
      img: "",
      title: "Performance Booster",
      text: "The new release 12 of the flexmobility platform contains a large number of improvements that make it even more effective and powerful. The platform is now even more code-efficient in both client and server technology. This makes the solution even more performant in many areas, enabling companies to operate solutions based on flexmobility platform 12 with the best possible app and web performance.",
    },
    {
      img: "",
      title: "Supported platform approach",
      text: "With flexmobility platform, support is a fundamental factor for the simple and complaint-free use of the solutions based on it. Important innovations have also been implemented in flexmobility platform 12, which ensure high stability and up-to-dateness of the platform.",
    },
  ],
  navbar: [
    { title: "Blog", link: "https://flexmobility.de/blog/" },
    { title: "Support", link: "https://flexmobility.de/support/" },
    { title: "Contact", link: "https://flexmobility.de/kontakt/" },
  ],
  sozialIcons: [
    {
      title: "Facebook",
      img: "imgs/Facebook_1.png",
      link: "https://www.facebook.com/3mobilitysolutions/",
    },
    {
      title: "LinkedIn",
      img: "imgs/Linkedin_1.png",
      link: "https://www.linkedin.com/company/3mobility-solutions-gmbh/",
    },
  ],
  downloadStore: [
    {
      title: "Ios",
      img: "imgs/app.png",
      link: "https://apps.apple.com/de/app/flexmobility-platform-12/id6462437937",
    },
    {
      title: "Android",
      img: "imgs/google.png",
      link: "https://play.google.com/store/apps/details?id=com.x3ms.flexmp12&gl=DE",
    },
    {
      title: "Windows",
      img: "imgs/microsoft.png",
      link: "https://apps.microsoft.com/detail/flexmobility-platform-12/9P4RHGH19WF5?hl=de-de&gl=DE",
    },
  ],

  buttons: {
    btn1 : 'Get Started Now',
    btn2: 'Get Started Now',
   },
 
   footer: {
     contact : 'Contact',
     PrivacyPolicy: 'Privacy Policy',
     SiteNotice : 'Site Notice',
     GTC: 'GTC',
    },
};


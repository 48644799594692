import React, { useState, useEffect } from "react";

import { dataDe, dataEn } from "./data.js";
import Navbar from "./Navbar.js";

function App() {
  const [data, setData] = useState(dataDe);

  useEffect(() => {
    // Check if there is a languageToggle value in local storage
    const storedLanguageToggle = JSON.parse(localStorage.getItem("languageToogle"));
    console.log(storedLanguageToggle)
    if (storedLanguageToggle !== null) {
      // If languageToggle value is found in local storage, set data based on its value
      if (storedLanguageToggle === 'EN') {
        // If languageToggle is true, set dataEn
        setData(dataEn);
      } else {
        // If languageToggle is false, set dataDe
        setData(dataDe);
      }
    } else {
      // If no languageToggle value is found in local storage, use navigator language
      if (window.navigator.language === "de") {
        // If user language is German, set dataDe
        setData(dataDe);
      } else {
        // If user language is not German, set dataEn
        setData(dataEn);
      }
    }
  

  }, []); // Empty dependency array ensures this effect runs once after the initial render


  const cardsMap = data.cards.map((i) => {
    return (
      <div className="card">
        <div className="imgs-group">
          <img className="main-imgs" src={i.img} alt={i.title} />
          <img className="sub-imgs" src={i.sub_img} alt={i.title} />
        </div>
        <div className="card-body">
          <h4>{i.title}</h4>
          <p>{i.text}</p>
        </div>
      </div>
    );
  });

  const cardsMap1 = data.cards1.map((i) => {
    return (
      <div className="card-land">
        <div className="card-body-land">
          <h4>{i.title}</h4>
          <p>{i.text}</p>
        </div>
      </div>
    );
  });

  const downloadStore = data.downloadStore.map((i) => {
    return (
      <div className="downloadStore-icons icons">
        <div>
          <a href={i.link}>
            <img src={i.img} style={{ width: "200px" }} alt={i.title} />{" "}
          </a>
        </div>
      </div>
    );
  });

  const sozialIcons = data.sozialIcons.map((i) => {
    return (
      <div className="sozialIcons-icons icons">
        <div>
          <a href={i.link}>
            <img src={i.img} style={{ width: "60px" }} alt={i.title} />{" "}
          </a>
        </div>
      </div>
    );
  });

  return (
    <div className="App">
      <div className="background"></div>
      <div className="background2"></div>
      <Navbar setData={setData} data={data} dataDe={dataDe} dataEn={dataEn} />
      <div className="section section1">
        <div className="title">
          <h1>
            <span className="titleWrrapr">{data.section1.blueTitle} </span>
            <span className="titleNumber"> {data.section1.version} </span>
            {data.section1.title}
          </h1>
          <p>{data.section1.text}</p>
          <div>
            <a className="button" href="https://flexmobility.de/">
            {data.buttons.btn1}
            </a>
          </div>
        </div>
        <div>
          <img className="logo3D-girl" src={data.section1.logoImg} alt="logo" />
        </div>
      </div>
      <div className="section">
        <div className="section3">
          <div className="cards1">{cardsMap1}</div>
          <div className="imgs">
            <img
              className="sub-logo-img"
              src={data.section3.logoImg}
              alt="konfigurieren-logo"
            />
          </div>
          <div className="title">
            <h1>{data.section3.title}</h1>
            <p>{data.section3.text}</p>
            <div>
              <a className="button" href="https://flexmobility.de/">
                {data.buttons.btn2}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section section2">
        <div className="title-sub">
          <h1>{data.section2.title}</h1>
          <p>{data.section2.text}</p>
        </div>
        <div className="cards">{cardsMap}</div>
      </div>

      {/* <div className="section section4">
        <div className="title-sub">
          {" "}
          <h1>Our Vision And Goal</h1>
        </div>
        <div className="cards1">{cardsMap1}</div>
      </div> */}
      <footer className=" fotter">
        <div className="downloadStore-icons icons">{downloadStore}</div>
        <div className="sozialIcons-icons icons">{sozialIcons}</div>
        <div className="footerLinks">
          <a href="https://flexmobility.de/kontakt/">{data.footer.contact}</a>{" "}
          <a href="https://flexmobility.de/datenschutz/">{data.footer.PrivacyPolicy}</a>
          <a href="https://flexmobility.de/impressum/">{data.footer.SiteNotice}</a>
          <a href="https://flexmobility.de/agb/">{data.footer.GTC}</a>
        </div>
      </footer>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";

const Navbar = ({ data, dataEn, dataDe, setData }) => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [showup, setShowup] = useState(false);
  const resulution = window.innerWidth;

  const handleToggle = (toggle) => {
    localStorage.setItem("languageToogle", JSON.stringify(toggle));
    const userToogle = JSON.parse(localStorage.getItem("languageToogle"));

    console.log(userToogle);
    if (userToogle === "EN") {
      setData(dataEn);
    } else {
      setData(dataDe);
    }
  };

  const isMobile = resulution >= 320 && resulution <= 850;

  const navData = data.navbar.map((i) => {
    return <a href={i.link}>{i.title}</a>;
  });

  useEffect(() => {
    setShowup(isMobile);
  }, [window.innerWidth, isMobile]);

  return (
    <div className="navbar">
      <img className="logo" src="imgs/logo.png" alt="flexmobility-logo" />
      <div
        style={{ display: !showup || menuToggle ? "flex" : "none" }}
        className="navbar-list"
      >
        {navData}
        {isMobile ? (
          <a href="https://portal.login-ssl.de/3msportal1/flexmobilityview/defaultview.aspx">
            Login
          </a>
        ) : (
          ""
        )}
      </div>
<div className="navRight">
      <div className="langugeBtn">
        <a onClick={() => handleToggle("DE")}>DE</a>
        <a onClick={() => handleToggle("EN")}>EN</a>
      </div>
      <div>
        {" "}
        {showup ? (
          <div className="menuIcon">
            <img
              onClick={() => setMenuToggle((prev) => !prev)}
              src="./imgs/bars.svg"
              style={{ width: "20px" }}
              alt="menü-icon"
            />
          </div>
        ) : (
          <a
            className="button"
            href="https://portal.login-ssl.de/3msportal1/flexmobilityview/defaultview.aspx"
          >
            Login
          </a>
        )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
